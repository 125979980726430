import { Button, UIHeading4, UIText2 } from '@cian/ui-kit';
import { IconCommunicationPhone16 } from '@cian/ui-kit-design-tokens/icons';
import cx from 'clsx';
import * as React from 'react';

import * as styles from './AreaPartsFullScreenControls.css';
import { FavoriteButtonContainer } from '../../containers/FavoriteButtonContainer';
import { FavoritesButtonContext } from '../../context';
import { IFavoriteButtonContext } from '../../context/favoritesButton';

export interface IFullScreenControlsProps {
  title: string;
  areaPartTitle: string;
  formattedPhones: string;
  callButtonText: string;
  phonesAvailable?: boolean;
  hideFavoritesButton?: boolean;
  nextSliderCompat?: boolean;
  setPhoneCollapse(): void;
}

const favoriteButtonContext: IFavoriteButtonContext = {
  customClass: styles['favorites'],
  placement: 'gallery',
  popupPivotX: 'right' as const,
};

export const AreaPartsFullScreenControls: React.FC<IFullScreenControlsProps> = props => {
  const [showPhones, setShowPhones] = React.useState(false);

  const {
    setPhoneCollapse,
    title,
    areaPartTitle,
    formattedPhones,
    phonesAvailable,
    hideFavoritesButton,
    nextSliderCompat,
    callButtonText,
  } = props;

  const onShowPhones = React.useCallback(() => {
    setPhoneCollapse();
    setShowPhones(true);
  }, [setPhoneCollapse]);

  return (
    <div className={cx(styles['controls'], nextSliderCompat && styles['controls--next-compat'])}>
      <div className={styles['title']}>
        <UIText2 color="white_60">{title}</UIText2>
        <UIHeading4 color="text-inverted-default">{areaPartTitle}</UIHeading4>
      </div>

      {phonesAvailable &&
        (showPhones ? (
          <span className={styles['phone']}>{formattedPhones}</span>
        ) : (
          <Button
            beforeIcon={<IconCommunicationPhone16 color="icon-inverted-default" />}
            size="XS"
            onClick={onShowPhones}
          >
            {callButtonText}
          </Button>
        ))}

      {!hideFavoritesButton && (
        <div className={styles['favorites-wrapper']}>
          <FavoritesButtonContext.Provider value={favoriteButtonContext}>
            <FavoriteButtonContainer />
          </FavoritesButtonContext.Provider>
        </div>
      )}
    </div>
  );
};
