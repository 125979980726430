import { useBooleanState } from '@cian/react-utils';
import * as React from 'react';

import { handleAvailability } from './internal/utils/handleAvailability';
import { trackOpenPhonesClick } from './tracking';
import { ICommercialContactsMainControllerProps } from './types';
import { useSimpleBotDetector } from '../../../hooks/useSimpleBotDetector';
import { formatNumber } from '../../../utils/phone';
import { showPhonesLimitAuth } from '../../../utils/showPhonesLimitAuth';
import { CommercialContactsMainView } from '../CommercialContactsMainView';

export const CommercialContactsMainController: React.FC<ICommercialContactsMainControllerProps> = props => {
  const {
    agent,
    user,
    offer,
    agentAvailability,
    setPhoneCollapse,
    offerChat,
    abGroup,
    phones,
    isCallTrackingButtonDisabled,
    openNotAvailablePopup,
  } = props;

  const ownOfferPopupState = useBooleanState();
  const phonesState = useBooleanState();
  const botDetector = useSimpleBotDetector();

  const phone = phones.map(formatNumber).join(', ');

  const handleOpenPhones = () => {
    trackOpenPhonesClick({ offer, agent, user, abGroup, kpId: null, bot: !botDetector.isHuman() });

    setPhoneCollapse();

    phonesState.setTrue();
  };

  const handlePhoneClick = (): void => {
    /**
     * @todo Удалить блок с экспериментом
     * @description Данный функционал появился в задаче CD-129223, будет удалён в задаче CD-129224
     * Удалить, если эксперимент будет не удачным
     */
    /* istanbul ignore next */
    if (isCallTrackingButtonDisabled) {
      showPhonesLimitAuth(offer, agent);
    } else {
      handleOpenPhones();

      const isAgentNotAvailable = Boolean(agentAvailability && !agentAvailability.available);
      const showAvailability = offerChat.isChatsEnabled && isAgentNotAvailable;

      /* istanbul ignore next */
      if (showAvailability) {
        handleAvailability({
          user,
          agent,
          offer,
          onOwnOfferPopupOpen: ownOfferPopupState.setTrue,
          onOpenNotAvailablePopup: openNotAvailablePopup,
        });
      }
    }
  };

  return (
    <CommercialContactsMainView
      isPhoneOpen={phonesState.state}
      isPopupOwnOfferOpen={ownOfferPopupState.state}
      phone={phone}
      onPhoneClick={handlePhoneClick}
      onPopupOwnOfferClose={ownOfferPopupState.setFalse}
    />
  );
};

CommercialContactsMainController.displayName = 'CommercialContactsMainController';
