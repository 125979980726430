import { Button, UIHeading4, UIText1 } from '@cian/ui-kit';
import * as React from 'react';

import { CallSourceWish } from 'shared/components/CallSourceWish';

import * as styles from './CommercialContactsMainView.css';
import { ICommercialContactsMainViewProps } from './types';
import { MoreInfoImage } from '../../images/MoreInfoImage';
import { PopupOwnOfferContainer } from '../../popups/own_offer/container';

export const CommercialContactsMainView: React.FC<ICommercialContactsMainViewProps> = props => {
  const { phone, isPhoneOpen, onPhoneClick, isPopupOwnOfferOpen, onPopupOwnOfferClose } = props;

  return (
    <div data-name="CommercialContactsMainView" className={styles['container']}>
      <div className={styles['content']}>
        <div className={styles['header']}>
          <UIHeading4>Остались вопросы по объявлению?</UIHeading4>
        </div>

        <div className={styles['description']} data-testid="description">
          <UIText1>Позвоните владельцу объявления и&nbsp;уточните необходимую информацию.</UIText1>
        </div>

        {isPhoneOpen ? (
          <span className={styles['phones']}>{phone}</span>
        ) : (
          <Button size="XS" onClick={onPhoneClick}>
            Позвонить
          </Button>
        )}

        {isPhoneOpen && (
          <div className={styles['sign']}>
            <CallSourceWish />
          </div>
        )}

        <PopupOwnOfferContainer isOpen={isPopupOwnOfferOpen} onClose={onPopupOwnOfferClose} />
      </div>
      <div className={styles['more-info-image-wrapper']}>
        <MoreInfoImage />
      </div>
    </div>
  );
};

CommercialContactsMainView.displayName = 'CommercialContactsMainView';
