import {
  ERepeatPolicy,
  RequestCancelError,
  TRepeatableErrorHandler,
  TRepeatableFunction,
} from '@cian/valuation-utils-component';
import { batch } from 'react-redux';

import { fetchBestPlaceAnalyticsPaymentStatus } from 'shared/services/bestPlaceAnalytics/fetchBestPlaceAnalyticsPaymentStatus';
import { EBestPlaceReportStatus } from 'shared/types/bestPlaceAnalytics';

import {
  setBestPlaceAnalyticsStatus,
  setBestPlaceAnalyticsStatusMessage,
  setBestPlaceAnalyticsUserEmail,
} from './actions';
import { MESSAGE_BY_STATUS } from './constants';
import { AnalyticsPaymentStatusUpdateService, initUpdatePaymentStatusService } from './utils';
import { unsubscribePaymentStatusUpdate } from './utils/unsubscribePaymentStatusUpdate';
import { IThunkActionCreator } from '../../store';

const DEFAULT_REPEAT_INTERVAL = 4000;
// Будем стучаться в ручку подтверждения оплаты в течение 10 мин (4 000 мс * 150 = 600 000 мс)
const DEFAULT_REPEAT_COUNT = 150;

export const subscribeBestPlaceAnalyticsStatusUpdate =
  (transactionId: string): IThunkActionCreator<Promise<void>> =>
  async (dispatch, _, context) => {
    batch(() => {
      dispatch(setBestPlaceAnalyticsStatus(EBestPlaceReportStatus.PaymentLoading));
      dispatch(setBestPlaceAnalyticsStatusMessage(MESSAGE_BY_STATUS[EBestPlaceReportStatus.PaymentLoading]));
    });

    const paymentStatusHelpers = initUpdatePaymentStatusService(DEFAULT_REPEAT_COUNT);

    const { logger } = context;

    const updateStatusRequest: TRepeatableFunction<void> = async () => {
      const { response, errorMessage, errorKey } = await fetchBestPlaceAnalyticsPaymentStatus(context, {
        transactionId,
      });

      paymentStatusHelpers.incrementRequestsCount();

      if (response && !errorMessage) {
        const message = MESSAGE_BY_STATUS[response.paymentStatus];

        if (response.paymentStatus !== EBestPlaceReportStatus.PaymentCreated) {
          /**
           * Отменяем запросы, если статус не PaymentCreated, т.е. произошла ошибка платежа: отменен, оплата не удалась
           * или что-то еще, что нас не интересует. Главное - дальше нет смысла пулять запросы на статус платежа.
           * Отменяем их отправку.
           */
          unsubscribePaymentStatusUpdate();
        }

        batch(() => {
          dispatch(setBestPlaceAnalyticsStatus(response.paymentStatus));
          dispatch(setBestPlaceAnalyticsStatusMessage(message));
          dispatch(setBestPlaceAnalyticsUserEmail(response.userEmail));
        });
      } else {
        /*
         * Ошибки 400 и 500 от "fetchBestPlaceAnalyticsPaymentStatus" попадут сюда.
         * Если ошибка 500, или ошибка 400, то мы продолжаем опрашивать сервер, поскольку упавший сервис дальше
         * по цепочке может восстановиться (билинг, платежный партнер). Делаем это "DEFAULT_REPEAT_COUNT" раз.
         */
        const isTransactionIdError = errorKey === 'transactionId';
        const isMaxRequestsCountError = paymentStatusHelpers.getIsMaxRequestCountExceeded();

        /**
         * Если ошибка со статусом 400 (и ее ключ === transactionId), то мы отписываемся от опроса сервера.
         * ИЛИ
         * Если сделали макс. допустимое кол-во запросов со статусом 400 или 500, то отписываемся от опроса сервера.
         */
        if (isTransactionIdError || isMaxRequestsCountError) {
          unsubscribePaymentStatusUpdate();

          const status = isTransactionIdError
            ? EBestPlaceReportStatus.WrongTransactionId
            : EBestPlaceReportStatus.UnknownServerError;
          const message = MESSAGE_BY_STATUS[status];

          batch(() => {
            dispatch(setBestPlaceAnalyticsStatus(status));
            dispatch(setBestPlaceAnalyticsStatusMessage(message));
          });
        }
      }
    };

    const updateStatusRequestError: TRepeatableErrorHandler = error => {
      unsubscribePaymentStatusUpdate();

      logger.error(error, {
        domain:
          'shared/actions/bestPlaceAnalytics/subscribeBestPlaceAnalyticsStatusUpdate.ts#updateStatusRequestError()',
      });
    };

    try {
      if (AnalyticsPaymentStatusUpdateService) {
        await AnalyticsPaymentStatusUpdateService.start(updateStatusRequest, updateStatusRequestError, {
          repeatPolicy: ERepeatPolicy.Always,
          maxRepeatCount: DEFAULT_REPEAT_COUNT,
          maxRepeatInterval: DEFAULT_REPEAT_INTERVAL,
          repeatInterval: DEFAULT_REPEAT_INTERVAL,
        });
      }
    } catch (error) {
      paymentStatusHelpers.resetRequestsCount();

      // Сюда мы попадаем только тогда, когда запросы прекращаются принудительно через "unsubscribePaymentStatusUpdate"
      // или выброшено исключение в "updateStatusRequest"
      if (RequestCancelError.is(error)) {
        return;
      }

      batch(() => {
        dispatch(setBestPlaceAnalyticsStatus(EBestPlaceReportStatus.UnknownServerError));
        dispatch(setBestPlaceAnalyticsStatusMessage(MESSAGE_BY_STATUS[EBestPlaceReportStatus.UnknownServerError]));
      });

      updateStatusRequestError(error);
    }
  };
