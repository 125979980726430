/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type { IGetPaymentStatusRequest, TGetPaymentStatusModel, IMappers, TGetPaymentStatusResponse } from './types';
import type { IPublicV1GetPaymentStatusResponseSchema } from '../../entities/responses/PublicV1GetPaymentStatusResponseSchema';
import type { IErrorResponseSchema } from '../../entities/schemas/ErrorResponseSchema';

export const defaultConfig: TGetPaymentStatusModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'POST',
  microserviceName: 'commercial-geo-analytics',
  pathApi: '/v1/get-payment-status/',
  requestType: 'json',
  responseType: 'json',
} as TGetPaymentStatusModel;

export function createGetPaymentStatusModel(parameters: IGetPaymentStatusRequest): TGetPaymentStatusModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetPaymentStatusOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: IGetPaymentStatusRequest;
}

export async function fetchGetPaymentStatus<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IGetPaymentStatusOptions<TResponse200, TResponse400>): Promise<
  TGetPaymentStatusResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(createGetPaymentStatusModel(parameters), config);
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IPublicV1GetPaymentStatusResponseSchema);
    }
    if (statusCode === 400) {
      return mappers[400](response as IErrorResponseSchema);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TGetPaymentStatusResponse;
}
