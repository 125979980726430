import {
  ERepeatPolicy,
  RequestCancelError,
  TRepeatableErrorHandler,
  TRepeatableFunction,
} from '@cian/valuation-utils-component';

import { setBuyAnalyticsPaymentStatusData } from './actions';
import { unsubscribePaymentStatusUpdate } from './unsubscribePaymentStatusUpdate';
import { AnalyticsPaymentStatusUpdateService, initUpdatePaymentStatusService } from './utils';
import { EBuyAnalyticsFormStatus } from '../../components/BuyAnalyticsModal';
import { selectBuyAnalyticsPaymentTransactionId } from '../../selectors/buyAnalyticsModal';
import { fetchBuyAnalyticsPaymentStatus } from '../../services/commercialAnalyticsReports/fetchBuyAnalyticsPaymentStatus';
import { IThunkActionCreator } from '../../store';

const DEFAULT_REPEAT_INTERVAL = 2000;
const DEFAULT_REPEAT_COUNT = 5;

export const subscribePaymentStatusUpdate =
  (): IThunkActionCreator<Promise<void>> => async (dispatch, getState, context) => {
    initUpdatePaymentStatusService();

    const { logger, config } = context;
    const state = getState();
    const transactionId = selectBuyAnalyticsPaymentTransactionId(state);

    if (!transactionId) {
      return;
    }

    const updateStatusRequest: TRepeatableFunction<void> = async () => {
      const { response, errorMessage } = await fetchBuyAnalyticsPaymentStatus(context, { transactionId });

      if (response && !errorMessage) {
        dispatch(setBuyAnalyticsPaymentStatusData(response));

        if (response.status !== EBuyAnalyticsFormStatus.Pending) {
          dispatch(unsubscribePaymentStatusUpdate());
        }
      }
    };

    const updateStatusRequestError: TRepeatableErrorHandler = error => {
      dispatch(unsubscribePaymentStatusUpdate());
      logger.error(error, {
        domain: 'shared/actions/buyAnalyticsModal/subscribePaymentStatusUpdate.ts#updateStatusRequestError()',
      });
    };

    try {
      await AnalyticsPaymentStatusUpdateService.start(updateStatusRequest, updateStatusRequestError, {
        repeatPolicy: ERepeatPolicy.Always,
        maxRepeatCount: config.get<number>('buyAnalytics.iap.paymentStatusUpdateCount') || DEFAULT_REPEAT_COUNT,
        maxRepeatInterval:
          config.get<number>('buyAnalytics.iap.paymentStatusUpdateInterval') || DEFAULT_REPEAT_INTERVAL,
        repeatInterval: config.get<number>('buyAnalytics.iap.paymentStatusUpdateInterval') || DEFAULT_REPEAT_INTERVAL,
      });
    } catch (error) {
      if (RequestCancelError.is(error)) {
        return;
      }

      updateStatusRequestError(error);
    }
  };
