/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type { IGetPaymentUrlRequest, TGetPaymentUrlModel, IMappers, TGetPaymentUrlResponse } from './types';
import type { IPublicV1GetPaymentUrlResponseSchema } from '../../entities/responses/PublicV1GetPaymentUrlResponseSchema';
import type { IErrorResponseSchema } from '../../entities/schemas/ErrorResponseSchema';

export const defaultConfig: TGetPaymentUrlModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'POST',
  microserviceName: 'commercial-geo-analytics',
  pathApi: '/v1/get-payment-url/',
  requestType: 'json',
  responseType: 'json',
} as TGetPaymentUrlModel;

export function createGetPaymentUrlModel(parameters: IGetPaymentUrlRequest): TGetPaymentUrlModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetPaymentUrlOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: IGetPaymentUrlRequest;
}

export async function fetchGetPaymentUrl<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IGetPaymentUrlOptions<TResponse200, TResponse400>): Promise<TGetPaymentUrlResponse | TResponse200 | TResponse400> {
  const { statusCode, response, headers } = await httpApi.fetch(createGetPaymentUrlModel(parameters), config);
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IPublicV1GetPaymentUrlResponseSchema);
    }
    if (statusCode === 400) {
      return mappers[400](response as IErrorResponseSchema);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TGetPaymentUrlResponse;
}
